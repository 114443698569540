import { Box, Progress } from "@mantine/core";
import React from "react";

const Test = () => {
  return (
    <Box m="xl">
      <h1>Test</h1>
      <Progress value={50} size="xl" radius="xl" />
    </Box>
  );
};

export default Test;
