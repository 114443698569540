import { Button, createStyles } from "@mantine/core";
import { useLanguage } from "./languageContext";

const rem = (pixels: number) => pixels * 1;

const useStyles = createStyles((theme) => ({
  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

const CTA = () => {
  const { classes } = useStyles();
  const { t } = useLanguage();

  return (
    <div className={classes.controls}>
      <Button
        className={classes.control}
        size="lg"
        variant="default"
        color="gray"
        onClick={() =>
          window.open("mailto:benjaminmerchin@gmail.com", "_blank")
        }
      >
        {t("sendEmail")}
      </Button>
      <Button
        className={classes.control}
        size="lg"
        onClick={() =>
          window.open("https://calendly.com/merchin/30min", "_blank")
        }
      >
        {t("scheduleCall")}
      </Button>
    </div>
  );
};

export default CTA;
