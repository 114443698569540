import { Grid, Image, Text, Title } from "@mantine/core";
import { HeroText } from "../Components/HeroText";
import profilePicture from "../Assets/profile-picture.jpg";
import { FeaturesGrid } from "../Components/Feature";
import { BadgeCard } from "../Components/BadgeCard";
import anaellePicture from "../Assets/anaelle.jpeg";
import aymericPicture from "../Assets/aymeric.jpeg";
import kirillPicture from "../Assets/k.jpeg";
import uply from "../Assets/uply.png";
import voilabs from "../Assets/voilabs.png";
import mobileapp from "../Assets/mobileapp.jpeg";
import startup from "../Assets/startup.jpeg";
import planning from "../Assets/planning.jpeg";
import drive from "../Assets/drive.jpeg";
import agenceWeb from "../Assets/agence_web.jpg";
// import mariePicture from "../Assets/marie.jpeg";
import { useLanguage } from "../Components/languageContext";
import Skills from "../Components/Skills";
import CTA from "../Components/CTA";

const Reviews = () => {
  const { t } = useLanguage();

  const reviewsData = [
    {
      image: aymericPicture,
      title: "Aymeric",
      descriptionKey: "aymericReview",
      dateKey: "may2020",
    },
    {
      image: anaellePicture,
      title: "Anaëlle",
      descriptionKey: "anaelleReview",
      dateKey: "may2020",
    },
    {
      image: agenceWeb,
      title: "Jean - CEO",
      descriptionKey: "jeanReview",
      dateKey: "january2023",
    },
    {
      image: kirillPicture,
      title: "Kirill",
      descriptionKey: "kirillReview",
      dateKey: "september2024",
    },
  ];

  return (
    <>
      {/* <Grid.Col xs={4}>
            <BadgeCard
              image={anaellePicture}
              title="Marie"
              description="Benjamin est quelqu’un de sérieux, qui s’implique vraiment dans les projets qu’il entreprend. Il est très structuré et cohérent. Il a toute ma confiance."
              country="Mai 2020"
              badges={[]}
            />
          </Grid.Col> */}
      <Title mt={50} mb={0}>
        {t("reviews")}
      </Title>
      <Grid m={0} mt={30} mb={90}>
        {reviewsData.map((review, index) => (
          <Grid.Col xs={4} key={index}>
            <BadgeCard
              image={review.image ?? ""}
              title={review.title}
              description={t(review.descriptionKey)}
              country={t(review.dateKey)}
              badges={[]}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};

const About = () => {
  const { t } = useLanguage();

  return (
    <Grid m={0} mt={50} pb={50}>
      <Grid.Col xs={5}>
        <Image
          maw={340}
          mx="auto"
          radius="md"
          src={profilePicture}
          alt={t("profilePictureAlt")}
        />
      </Grid.Col>
      <Grid.Col xs={7}>
        <Text style={{ textAlign: "left" }}>
          <Title style={{ justifyContent: "left", marginBottom: 10 }}>
            {t("about")}
          </Title>
          {t("aboutParagraph1")}
          <br />
          <br />
          {t("aboutParagraph2")}
          <br />
          <br />
          {t("aboutParagraph3")}
          <br />
          <br />
          {t("aboutParagraph4")}
        </Text>
      </Grid.Col>
    </Grid>
  );
};

const Portfolio = () => {
  const { t } = useLanguage();

  const portfolioItems = [
    {
      image: voilabs,
      titleKey: "voilabsTitle",
      descriptionKey: "voilabsDescription",
      year: "2024",
      badges: [
        { emoji: "🤖", labelKey: "aiChatbotLabel" },
        { emoji: "🧩", labelKey: "integrableWidgetLabel" },
        { emoji: "📊", labelKey: "messageHistoryLabel" },
      ],
    },
    {
      image: uply,
      titleKey: "uplyTitle",
      descriptionKey: "uplyDescription",
      year: "2024",
      badges: [
        { emoji: "🧠", labelKey: "personalDevelopmentLabel" },
        { emoji: "📚", labelKey: "learningLabel" },
      ],
    },
    {
      image: startup,
      titleKey: "adStartupTitle",
      descriptionKey: "adStartupDescription",
      year: "2023",
      badges: [
        { emoji: "🖥️", labelKey: "backOfficeLabel" },
        { emoji: "🌐", labelKey: "clientPortalLabel" },
        { emoji: "📱", labelKey: "mobileAppLabel" },
      ],
    },
    {
      image: drive,
      titleKey: "documentReceptionTitle",
      descriptionKey: "documentReceptionDescription",
      year: "2023",
      badges: [
        { emoji: "📁", labelKey: "documentManagementLabel" },
        { emoji: "☁️", labelKey: "googleDriveLabel" },
        { emoji: "⚛️", labelKey: "reactNestjsLabel" },
      ],
    },
    {
      image: planning,
      titleKey: "hrPlatformTitle",
      descriptionKey: "hrPlatformDescription",
      year: "2024",
      badges: [
        { emoji: "📅", labelKey: "automaticPlanningLabel" },
        { emoji: "🏖️", labelKey: "leaveManagementLabel" },
        { emoji: "📊", labelKey: "taskTrackingLabel" },
      ],
    },
    {
      image: mobileapp,
      titleKey: "socialNetworkAppTitle",
      descriptionKey: "socialNetworkAppDescription",
      year: "2023",
      badges: [
        { emoji: "📱", labelKey: "reactNativeExpoLabel" },
        { emoji: "💬", labelKey: "integratedChatLabel" },
        { emoji: "🛍️", labelKey: "affiliationLabel" },
      ],
    },
  ];

  return (
    <>
      <Title mt={50} mb={0}>
        {t("portfolioTitle")}
      </Title>
      <Text size="sm" color="dimmed" mt={10}>
        {t("portfolioSubtitle")}
      </Text>
      <Grid m={0} mt={30} mb={90}>
        {portfolioItems.map((item, index) => (
          <Grid.Col xs={4} key={index}>
            <BadgeCard
              image={item.image}
              title={t(item.titleKey)}
              description={t(item.descriptionKey)}
              country={item.year}
              badges={item.badges.map((badge) => ({
                emoji: badge.emoji,
                label: t(badge.labelKey),
              }))}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};

const Home = () => {
  const { t } = useLanguage();

  return (
    <>
      <HeroText />

      <About />

      <Portfolio />

      <Reviews />

      <FeaturesGrid />

      <Skills />

      <Title mt={50} mb={0}>
        {t("rateTitle")}
      </Title>
      <Text mt={30} mb={50} size={24} fw={700}>
        {t("rateAmount")}
      </Text>

      <div style={{ paddingRight: 20, paddingLeft: 20 }}>
        <CTA />
      </div>
    </>
  );
};
export default Home;

// J'essaye d'avoir une approche pragmatique :
// 1. Bien comprendre les besoins du client
// 2. Simplifier, optimiser
// 3. Coder

// Ma stack de prédilection est React / Typescript / NestJs / AWS / Prisma.
