import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Layout from "./Layout";
import Test from "./Pages/Test";
// Once I will need to implement authentication, I will need to use the following:
// <AuthenticatedRoute>
// </AuthenticatedRoute>

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/test"
          element={
            <Layout>
              <Test />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
