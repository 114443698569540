import { useRef, useEffect } from "react";
import * as THREE from "three";

const ThreeJSBackground: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mountRef.current) return;

    // Store the ref value in a variable to use in cleanup
    const currentMount = mountRef.current;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    currentMount.appendChild(renderer.domElement);

    // Fog to create depth effect
    scene.fog = new THREE.FogExp2(0x0c0b13, 0.01);

    // Enhanced Grid setup - more expansive and double grid for depth effect
    const gridSize = 200;
    const gridDivisions = 40;
    const mainGrid = new THREE.GridHelper(
      gridSize,
      gridDivisions,
      0x42dcdb,
      0x42dcdb
    );
    mainGrid.material.transparent = true;
    mainGrid.material.opacity = 0.6;
    scene.add(mainGrid);

    // Second grid for layered effect
    const secondaryGrid = new THREE.GridHelper(
      gridSize,
      gridDivisions / 2,
      0xff5e83,
      0x42dcdb
    );
    secondaryGrid.position.y = -0.5;
    secondaryGrid.material.transparent = true;
    secondaryGrid.material.opacity = 0.3;
    scene.add(secondaryGrid);

    // Enhanced particles with varied geometric shapes - Removing boxes, adding more complex shapes
    const createEnhancedParticles = () => {
      const group = new THREE.Group();

      // Collection of geometries for variety - Removed BoxGeometry and added more complex shapes
      const geometries = [
        new THREE.TetrahedronGeometry(0.5, 0),
        new THREE.OctahedronGeometry(0.5, 0),
        new THREE.DodecahedronGeometry(0.5, 0),
        new THREE.TorusGeometry(0.3, 0.1, 16, 16),
        new THREE.TorusKnotGeometry(0.3, 0.1, 64, 16), // Ajout d'un noeud torique
        new THREE.SphereGeometry(0.3, 16, 16), // Sphère plus détaillée
        new THREE.IcosahedronGeometry(0.4, 0), // Forme à 20 faces
        // Ajout de nouvelles formes plus complexes
        new THREE.TorusKnotGeometry(0.35, 0.15, 100, 16, 2, 3), // Nœud torique plus complexe
        new THREE.ConeGeometry(0.4, 0.8, 6), // Cône hexagonal
        new THREE.CylinderGeometry(0.3, 0.3, 0.8, 8), // Cylindre octogonal
      ];

      // Collection of colors for variety
      const geometryColors = [
        0xff5e83, // Pink
        0x42dcdb, // Cyan
        0x9d4edd, // Purple
        0x07ebe9, // Teal
        0xfdffb6, // Yellow
        0xfe53bb, // Rose vif
        0x00f5d4, // Turquoise
        0x7b00ff, // Violet électrique
      ];

      // MODIFICATION: Augmentation du nombre de formes (de 100 à 175)
      const objectCount = 175;

      // Create more geometric objects with larger sizes
      for (let i = 0; i < objectCount; i++) {
        // Choose random geometry and color
        const geometry =
          geometries[Math.floor(Math.random() * geometries.length)];
        const color =
          geometryColors[Math.floor(Math.random() * geometryColors.length)];

        // Toutes les formes sont maintenant en wireframe
        const material = new THREE.MeshBasicMaterial({
          color: color,
          wireframe: true,
          transparent: true,
          opacity: Math.random() * 0.5 + 0.3, // Between 0.3 and 0.8
        });

        const object = new THREE.Mesh(geometry, material);

        // Position randomly in space - Increase spread
        object.position.x = (Math.random() - 0.5) * 180;
        object.position.y = (Math.random() - 0.5) * 80;
        object.position.z = (Math.random() - 0.5) * 180;

        // MODIFICATION: Augmentation significative de la taille des formes (de 1.0-4.5 à 2.0-6.0)
        const scale = Math.random() * 4.0 + 2.0;
        object.scale.set(scale, scale, scale);

        // Store rotation speed as a property - reduced slightly for larger objects
        object.userData = {
          rotationSpeedX: (Math.random() - 0.5) * 0.015,
          rotationSpeedY: (Math.random() - 0.5) * 0.015,
          rotationSpeedZ: (Math.random() - 0.5) * 0.015,
          movementSpeed: Math.random() * 0.04,
          direction: new THREE.Vector3(
            (Math.random() - 0.5) * 0.008, // Mouvements un peu plus lents pour les grandes formes
            (Math.random() - 0.5) * 0.008,
            (Math.random() - 0.5) * 0.008
          ),
        };

        group.add(object);
      }

      // MODIFICATION: Ajout de quelques très grandes formes en arrière-plan
      for (let i = 0; i < 10; i++) {
        const largeGeometries = [
          new THREE.IcosahedronGeometry(0.4, 0),
          new THREE.DodecahedronGeometry(0.5, 0),
          new THREE.TorusKnotGeometry(0.3, 0.1, 64, 16, 2, 3),
        ];

        const geometry =
          largeGeometries[Math.floor(Math.random() * largeGeometries.length)];
        const color =
          geometryColors[Math.floor(Math.random() * geometryColors.length)];

        const material = new THREE.MeshBasicMaterial({
          color: color,
          wireframe: true,
          transparent: true,
          opacity: 0.25, // Plus transparentes pour effet de profondeur
        });

        const object = new THREE.Mesh(geometry, material);

        // Positionnées plus loin pour effet de profondeur
        object.position.x = (Math.random() - 0.5) * 120;
        object.position.y = (Math.random() - 0.5) * 60;
        object.position.z = -250 - Math.random() * 150;

        // Beaucoup plus grandes (augmentation de 7-15 à 10-20)
        const scale = Math.random() * 10 + 10; // Entre 10 et 20
        object.scale.set(scale, scale, scale);

        // Rotation plus lente
        object.userData = {
          rotationSpeedX: (Math.random() - 0.5) * 0.007,
          rotationSpeedY: (Math.random() - 0.5) * 0.007,
          rotationSpeedZ: (Math.random() - 0.5) * 0.007,
          movementSpeed: 0, // Pas de mouvement pour les très grandes formes
          direction: new THREE.Vector3(0, 0, 0),
        };

        group.add(object);
      }

      return group;
    };

    const enhancedParticles = createEnhancedParticles();
    scene.add(enhancedParticles);

    // Camera position - moved back for wider view
    camera.position.set(0, 18, 40);
    camera.lookAt(0, 0, -50);

    // Animation loop
    let frame = 0;
    const animate = () => {
      requestAnimationFrame(animate);

      frame += 0.01;

      // Move grids forward to create an infinite corridor effect
      mainGrid.position.z = (frame % 5) * 20 - 10;
      secondaryGrid.position.z = (frame % 7) * 15 - 10;

      // Animate enhanced particles
      enhancedParticles.children.forEach((child) => {
        if (child instanceof THREE.Mesh) {
          // Rotate each object
          child.rotation.x += child.userData.rotationSpeedX;
          child.rotation.y += child.userData.rotationSpeedY;
          child.rotation.z += child.userData.rotationSpeedZ;

          // Move each object slightly (if it has direction)
          if (child.userData.direction.lengthSq() > 0) {
            child.position.add(child.userData.direction);

            // Boundary check and bounce - Enlarged boundaries
            if (Math.abs(child.position.x) > 90)
              child.userData.direction.x *= -1;
            if (Math.abs(child.position.y) > 45)
              child.userData.direction.y *= -1;
            if (Math.abs(child.position.z) > 90)
              child.userData.direction.z *= -1;
          }

          // Pulse size - amplitude proportional to object size
          const baseScale =
            child.scale.x / (1 + Math.sin(frame * 2 + child.id) * 0.1);
          const pulse = Math.sin(frame * 2 + child.id) * 0.1 + 1;
          child.scale.set(
            baseScale * pulse,
            baseScale * pulse,
            baseScale * pulse
          );
        }
      });

      // Slowly rotate the entire particle system for added effect
      enhancedParticles.rotation.y += 0.001;

      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      if (currentMount) {
        currentMount.removeChild(renderer.domElement);
      }

      scene.clear();
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "linear-gradient(to bottom, #0c0b13 0%, #151438 100%)",
        zIndex: 1,
      }}
    />
  );
};

export default ThreeJSBackground;
