import expo from "../Components/VoiLabs/Assets/logo-wordmark.png";
import expo_white from "../Components/VoiLabs/Assets/logo-wordmark-light.png";
import nest from "../Components/VoiLabs/Assets/nestjs-ar21.svg";
import nextjs_white from "../Components/VoiLabs/Assets/nextjs_white.svg";
import nextjs_black from "../Components/VoiLabs/Assets/nextjs_black.svg";
import { Image, SimpleGrid, Title, useMantineTheme } from "@mantine/core";
import { useLanguage } from "./languageContext";

const Skills = () => {
  const theme = useMantineTheme();
  const { t } = useLanguage();

  return (
    <>
      <Title mt={50} mb={0}>
        {t("skillsTitle")}
      </Title>
      <SimpleGrid
        cols={6}
        spacing="lg"
        breakpoints={[
          { maxWidth: 992, cols: 4, spacing: "md" },
          { maxWidth: 768, cols: 3, spacing: "sm" },
          { maxWidth: 576, cols: 2, spacing: "sm" },
        ]}
        mt={30}
      >
        <Image
          src="https://cdn.iconscout.com/icon/free/png-256/free-react-4-1175110.png"
          width={45}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://www.datocms-assets.com/45470/1631026680-logo-react-native.png"
          width={70}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://humancoders-formations.s3.amazonaws.com/uploads/course/logo/230/formation-typescript.png"
          width={45}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/800px-Amazon_Web_Services_Logo.svg.png"
          width={70}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://cdn.worldvectorlogo.com/logos/prisma-2.svg"
          width={70}
          style={{ margin: "auto" }}
        />
        <Image
          src={theme.colorScheme === "light" ? expo : expo_white}
          width={70}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1024px-Unofficial_JavaScript_logo_2.svg.png"
          width={45}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png"
          width={55}
          style={{ margin: "auto" }}
        />
        <Image src={nest} width={90} style={{ margin: "auto" }} />
        <Image
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/2560px-Node.js_logo.svg.png"
          width={70}
          style={{ margin: "auto" }}
        />
        <Image
          src="https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png"
          width={70}
          style={{ margin: "auto" }}
        />
        <Image
          src={theme.colorScheme === "light" ? nextjs_black : nextjs_white}
          width={70}
          style={{ margin: "auto" }}
        />
      </SimpleGrid>
    </>
  );
};

export default Skills;
