import { createStyles, Anchor, Group, ActionIcon, rem } from "@mantine/core";
import {
  IconBrandYoutube,
  // IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandGithub,
} from "@tabler/icons";
// import Logo from "./Logo";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.md} ${theme.spacing.md}`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },
}));

// interface FooterBarProps {
//   links: { link: string; label: string }[];
// }

const links = [
  // { link: "#", label: "Profil Malt" },
  { link: "#", label: `Benjamin Merchin - ${new Date().getFullYear()}` },
  // { link: "#", label: "Blog" },
];

export default function FooterBar(/*{ links }: FooterBarProps*/) {
  const { classes } = useStyles();
  const voilabs = ["/voilabs", "/ai"].includes(window.location.pathname);
  const items = links.map((link) => (
    <Anchor<"a">
      color="dimmed"
      key={link.label}
      href={link.link}
      sx={{ lineHeight: 1 }}
      onClick={(event) => event.preventDefault()}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  if (voilabs) {
    return (
      <div className={classes.footer}>
        <div className={classes.inner}>
          <Group className={classes.links}>{items}</Group>

          <Group spacing="xs" position="right" noWrap>
            <ActionIcon
              size="lg"
              variant="default"
              radius="xl"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/voilabs-ai/",
                  "_blank"
                )
              }
            >
              <IconBrandLinkedin size={18} stroke={1.5} />
            </ActionIcon>
          </Group>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <div className={classes.inner}>
        {/* <Logo /> */}

        <Group className={classes.links}>{items}</Group>

        <Group spacing="xs" position="right" noWrap>
          <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open("https://x.com/BenjaminMerchin", "_blank")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 300 300.251"
              version="1.1"
            >
              <path
                d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
                fill="currentColor"
              />
            </svg>
          </ActionIcon>
          <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open("https://www.youtube.com/@benjaminmerchin", "_blank")
            }
          >
            <IconBrandYoutube size={18} stroke={1.5} />
          </ActionIcon>
          {/* <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open(
                "https://www.instagram.com/benjaminmerchin/",
                "_blank"
              )
            }
          >
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon> */}
          <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open("https://github.com/benjaminmerchin", "_blank")
            }
          >
            <IconBrandGithub size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/benjaminmerchin/",
                "_blank"
              )
            }
          >
            <IconBrandLinkedin size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </div>
    </div>
  );
}
