import {
  Title,
  Text,
  Container,
  useMantineTheme,
  keyframes,
  createStyles,
} from "@mantine/core";
import { useLanguage } from "./languageContext";
import CTA from "./CTA";
import { useEffect, useState } from "react";
import ThreeJSBackground from "./ThreeJSBackground";

const rem = (pixels: number) => pixels * 1;

// Animation keyframes
const glowAnimation = keyframes({
  "0%": {
    textShadow:
      "0 0 5px rgba(66, 220, 219, 0.7), 0 0 10px rgba(66, 220, 219, 0.5), 0 0 15px rgba(66, 220, 219, 0.3)",
  },
  "50%": {
    textShadow:
      "0 0 10px rgba(66, 220, 219, 0.9), 0 0 20px rgba(66, 220, 219, 0.7), 0 0 30px rgba(66, 220, 219, 0.5)",
  },
  "100%": {
    textShadow:
      "0 0 5px rgba(66, 220, 219, 0.7), 0 0 10px rgba(66, 220, 219, 0.5), 0 0 15px rgba(66, 220, 219, 0.3)",
  },
});

const fadeInAnimation = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

// Nouvelles animations pour le titre
const slideInTopAnimation = keyframes({
  "0%": { transform: "translateY(-30px)", opacity: 0 },
  "100%": { transform: "translateY(0)", opacity: 1 },
});

// Utilisation de createStyles pour gérer les pseudo-éléments
const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(52),
    fontFamily: "'Syncopate', 'Orbitron', sans-serif",
    fontWeight: 900,
    letterSpacing: "0px",
    color: "#ffffff",
    textTransform: "uppercase",
    textShadow:
      "0 0 10px rgba(66, 220, 219, 0.7), 0 0 20px rgba(66, 220, 219, 0.5)",
    animation: `${slideInTopAnimation} 1s ease-out`,
    background: "linear-gradient(45deg, #E0F7FA 0%, #42dcdb 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    position: "relative",
    display: "inline-block",
    padding: "0.5rem 0",

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "25%",
      right: "25%",
      height: "2px",
      background: "linear-gradient(90deg, transparent, #42dcdb, transparent)",
      animation: `${glowAnimation} 2s infinite`,
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(36),
    },
  },

  agileText: {
    fontFamily: "inherit",
    background: "linear-gradient(45deg, #FF5E83 0%, #FF8A9E 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textShadow: "none",
    position: "relative",
    display: "inline-block",

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-5px",
      left: 0,
      width: "100%",
      height: "3px",
      background: "#FF5E83",
      transform: "scaleX(0)",
      transition: "transform 0.3s ease",
    },

    "&:hover::after": {
      transform: "scaleX(1)",
    },
  },

  description: {
    fontWeight: 700,
    fontFamily: "'VT323', monospace",
    fontSize: rem(22),
    color: "#E0F7FA",
    animation: `${fadeInAnimation} 1.5s ease-in-out`,
    width: "100%",
    textShadow: "0 0 5px rgba(66, 220, 219, 0.5)",
    lineHeight: 1.6,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(18),
    },
  },
}));

export function HeroText() {
  const { t } = useLanguage();
  const theme = useMantineTheme();
  const [mounted, setMounted] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Container
      style={{
        position: "relative",
        paddingTop: rem(180),
        paddingBottom: rem(180),
        overflow: "hidden",
        [theme.fn.smallerThan("sm")]: {
          paddingTop: rem(120),
          paddingBottom: rem(120),
        },
      }}
      size="100%"
      p={0}
    >
      {/* Three.js Background */}
      {mounted && <ThreeJSBackground />}

      <Container
        size="lg"
        style={{
          position: "relative",
          zIndex: 10,
          textAlign: "center",
        }}
      >
        <Title className={classes.title}>
          {t("freelance")}{" "}
          <Text component="span" className={classes.agileText} inherit>
            {t("agile")}
          </Text>{" "}
          {t("forAllYourProjects")}
        </Title>

        <Container
          p={0}
          size={600}
          style={{
            marginTop: 40,
            position: "relative",
          }}
        >
          <Text size="lg" className={classes.description}>
            {t("heroDescription")}
          </Text>
        </Container>

        <div style={{ marginTop: 60 }}>
          <CTA />
        </div>
      </Container>
    </Container>
  );
}
